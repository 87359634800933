import axios from "axios";
import React, { useEffect, useState } from "react";
import BookLists from "../Components/BookLists";

import LeftFilterBookList from "../Components/LeftFilterBookList";
import StickyButton from "../Components/StickyButton";
import { url } from "../Services/Url";

const AllBookList = () => {
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState(null);

  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const prevPage = () => setCurrentPage(currentPage - 1);
  const nextPage = () => setCurrentPage(currentPage + 1);

  const fetchData = async () => {
    setLoading(true);
    await axios
      .post(`${url}/api/v1/all-books`, {...filter, page:currentPage})
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    fetchData();
  }, [filter, currentPage]);
  
  
  return (
    <>
      <StickyButton />

      <div className="shop-main-area mt-5" style={{ marginBottom: "70px" }}>
        <div className="container">
          <div className="row">
            <LeftFilterBookList
              filter={filter}
              setFilter={setFilter}
              
            />
            {/* LeftFilterBookList->BookLists->TabBook->Pagination */}
            {/* TabBook->SingleListBook */}
            {/* TabBook->SingleGridBook */}
            <BookLists 
            data={data}
            loading={loading}
            currentPage={currentPage}
            paginate={paginate}
            nextPage={nextPage}
            prevPage={prevPage}
             />
          </div>
        </div>
      </div>
    </>
  );
};

export default AllBookList;
