import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { AuthorsData } from "../Services/AuthorsData";
const WeeklyTopAuthors = () => {
  const [author, setAuthor] = useState([]);
  let location = useLocation();
  let checkLocation = location.pathname;
  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get("https://jsonplaceholder.typicode.com/photos")
        .then((res) => setAuthor(res.data.slice(0, 10)))
        .catch((err) => console.log(err.message));
    };
    fetchData();
  }, []);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    arrow: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div
        className="recent-post-area mt-5"
      // style={{ paddingTop: "95px", paddingBottom: "100px" }}
      >
        <div className="container">
          <div className="row">
            <div className={checkLocation === "/" ? "col-lg-8" : "col-lg-12"}>
              <div
                className={
                  checkLocation === "/"
                    ? "section-title text-left  section-title-res"
                    : "section-title text-center  section-title-res"
                }
                style={{ marginBottom: "30px" }}
              >
                <h3 style={{ fontWeight: "bold" }}>জনপ্রিয় লেখকগণ</h3>
              </div>
            </div>
            <div className={checkLocation === "/" ? "col-lg-4" : "d-none"}>
              <div className="section-title text-right  section-title-res">
                <Link
                  to="/authors"
                  type="button"
                  className="author-view btn btn-outline-success"
                >
                  View All
                </Link>
              </div>
            </div>
          </div>
          {/* <hr /> */}
          <Slider {...settings} style={{ marginTop: 40 }}>
            {AuthorsData.slice(0, 8).map((data, index) => (
              <Fragment key={index}>
                <div className="col-12" key={index} style={{ display: "flex", justifyContent: 'center', flexDirection: "column", alignItems: "center" }}>
                  <img
                    className="card-img-top"
                    src={
                      data?.files?.length
                              ? data?.base_image?.path
                              : "/img/product/1.jpg"
                    }
                    alt=""
                    style={{
                      borderRadius: "50%",
                      border: "1px solid #c7c7c7",
                      width: "140px",
                      height: "140px",
                    }}
                  />
                  <div className="card-body text-center">
                    <Link
                      to="#"
                      className="authors-title card-title"
                      style={{ textDecoration: "none", color: "#333" }}
                    >
                      {data.author}
                    </Link>
                  </div>
                </div>
              </Fragment>
            ))}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default WeeklyTopAuthors;
