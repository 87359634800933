import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ScaleLoader } from "react-spinners";
import { url } from "../Services/Url";
import "./LeftFilterBookList.css";
const LeftFilterBookList = ({
  filter,
  setFilter,
}) => {
  const divStyle = {
    overflowY: "scroll",
    height: "315px",
    position: "relative",
    fontFamily: "Hind Siliguri",
  };
  const decorationnone = {
    textDecoration: "none",
    fontFamily: "Hind Siliguri",
  };

  const fetchData = async () => {
    await axios
      .get(`${url}/api/v1/publishers`)
      .then((res) => {
        setPublishers(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });

      await axios
      .get(`${url}/api/v1/authors`)
      .then((res) => {
        const data = 
        setAuthors(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  

  useEffect(() => {
    fetchData();
  }, []);

  // const [categories, setCategories] = useState([
  //   { id: 1, name: "Afnan", slug: "test" },
  //   { id: 2, name: "Afnan", slug: "test" },
  // ]);
  const [publishers, setPublishers] = useState([]);
  const [authors, setAuthors] = useState([]);

  const ftilterData = (name, item) => {
    setFilter({ ...filter, [name]: item });
  };

  const [checked, setChecked] = useState([]);
  const handleCheck = (event) => {
    var updatedList = [...checked];

    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
  };

  useEffect(() => {
    setFilter({ ...filter, authors: checked });
  }, [checked]);

  return (
    <>
      <div className="col-lg-3 col-md-12 col-12 order-lg-1 order-2 mt-sm-50 mt-xs-40">
        <div className="shop-left">
          <div className="card" style={{ borderRadius: "0%" }}>
            <div
              className="card-body justify-content-between"
              style={{
                padding: "0.7rem",
                borderBottom: "1px solid #b3b3b3",
              }}
            >
              <div className="left-title">
                {" "}
                <h4>
                  <i className="fas fa-filter"> </i> Filter
                </h4>
              </div>
            </div>
          </div>

          
          {/* <div className="card" style={{ borderRadius: "0%" }}>
            <div
              className="card-body justify-content-between"
              style={{
                padding: "0.7rem",
                borderBottom: "1px solid #b3b3b3",
              }}
            >
              <div className="left-title" style={{ marginBottom: "20px" }}>
                <h4>
                  Category <i className="fas fa-arrow-down"></i>
                </h4>
              </div>

              <div className="left-menu" style={divStyle}>
                <ul style={{ paddingLeft: "30px" }}>
                  {categories?.map((item, index) => {
                    var url = "books/categories/" + item?.slug;
                    return (
                      <li style={{ listStyle: "circle" }} key={index}>
                        {" "}
                        <Link
                          style={{
                            ...decorationnone,
                            color:
                              JSON.stringify(filter?.category) ==
                              JSON.stringify(item)
                                ? "green"
                                : "",
                          }}
                          onClick={() => ftilterData("category", item)}
                        >
                          {item?.name}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div> */}

          {/* Publishers */}
          <div
            className="card"
            style={{ borderRadius: "0%", marginTop: "5px" }}
          >
            <div
              className="card-body justify-content-between"
              style={{
                padding: "0.7rem",
                borderBottom: "1px solid #b3b3b3",
              }}
            >
              <div className="left-title" style={{ marginBottom: "20px" }}>
                <h4>Publishers</h4>
              </div>
              <div className="left-menu" style={divStyle}>
                <ul style={{ paddingLeft: "30px" }}>
                  {publishers.length ? publishers?.map((item, index) => {
                    var url = "books/publishers/" + item?.slug;
                    return (
                      <li style={{ listStyle: "circle" }} key={index}>
                        {" "}
                        <Link
                          style={{
                            ...decorationnone,
                            color:
                              JSON.stringify(filter?.publisher) ==
                              JSON.stringify(item)
                                ? "green"
                                : "",
                          }}
                          onClick={() => ftilterData("publisher", item)}
                        >
                          {item?.name}
                        </Link>
                      </li>
                    );
                  })
                  : 
                  <div style={{textAlign: "center"}}>
            <ScaleLoader color="#24902f" />
          </div>
                  }
                  
                </ul>
              </div>
            </div>
          </div>
          {/* Publishers end */}

          {/* Authors start */}
          <div
            className="card"
            style={{ borderRadius: "0%", marginTop: "5px" }}
          >
            <div
              className="card-body justify-content-between"
              style={{
                padding: "0.7rem",
                borderBottom: "1px solid #b3b3b3",
              }}
            >
              <div className="left-title" style={{ marginBottom: "20px" }}>
                <h4>Authors</h4>
              </div>
              <div className="left-menu" style={divStyle}>
                {authors.length ? authors?.map((item, index) => {
                  return (
                    <div className="form-check " key={index}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value={item?.id}
                        onChange={handleCheck}
                      />
                      <label
                        className="form-check-label"
                        for="flexCheckDefault"
                      >
                        {item?.name}
                      </label>
                    </div>
                  );
                })
                : 
                <div style={{textAlign: "center"}}>
          <ScaleLoader color="#24902f" />
        </div>
        }
              </div>
            </div>
          </div>
          {/* Authors End */}

          <div className="left-title-2">
            <h2>My Wish List</h2>
            <p>You have no items in your wish list.</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeftFilterBookList;
