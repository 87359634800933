import React from 'react';
import { Link } from 'react-router-dom';


const NavMenuChildren = props => {

    console.log("Afnan Props", props);

    return (
            <div className="mega-menu mega-menu-2" style={{zIndex: 999999}}>
                {props.children?.map((item, index) => (
                    <span key={index}>
                        <Link
                            key={item.id}
                            to={props?.url+item?.id}                           
                        >
                            {item.name}
                        </Link>
                    </span>
                ))}
            </div>
    );
}

export default NavMenuChildren;

