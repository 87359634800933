import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import Rating from "react-rating";
import { Link, useHistory, useParams } from "react-router-dom";
import { Link as Link2 } from "react-scroll";
import { ScaleLoader } from "react-spinners";
import { url } from "../Services/Url";
import ProductInfo from "./ProductInfo";

import { CartContext } from "../Context/GlobalContext/GlobalState";

import { AuthContext } from "../Context/AuthState";
import MultiThreeRowSlickSlider from "./MultiThreeRowSlickSlider";
import ReviewRatings from "./ReviewRatings";
const BookDetails = () => {
  const { addToCart } = useContext(CartContext);
  const { createWishList, state } = useContext(AuthContext);
  const [value] = useState(1);
  const { bookname } = useParams();
  const [boi, setBoi] = useState([]);
  const [ratings, setRatings] = useState([]);
  const [relatedBooks, setRelated] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchBooks = async () => {
      setLoading(true);
      await axios
        .get(`${url}/api/v1/products/${bookname}`, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          setLoading(false);
          setBoi(res.data.product);
          setRatings(res.data.reviews);
          setRelated(res.data.related.products);
          window.scrollTo(0, 0);
        })
        .catch((err) => {
          console.log(err.message);
        });
    };
    fetchBooks();
  }, [bookname]);

  const myratings = ratings ? ratings.map((item) => Number(item.rating)) : "";
  const test = ratings
    ? myratings.reduce((n, current) => n + current, 0) / ratings.length
    : 0;

  const loaderStyle = { textAlign: "center" };

  const star = ratings
    ? ratings.map((item) => item.rating)
    : console.log("no star");
  const reducer = (sum, val) => (sum + val) / star.length;
  const avgStar = ratings.length > 0 ? star.reduce(reducer) : "none";

  const history = useHistory();

  const redirect = () => {
    history.push("/login");
  };
  return (
    <>
      <div className="product-main-area" style={{ marginBottom: "70px" }}>
        <div className="container">
          <div className="row mt-5 mb-5">
            <div className="col-lg-9 col-md-12 col-12 order-lg-1 order-1">
              {loading ? (
                <div style={loaderStyle}>
                  <ScaleLoader color="#24902f" />
                </div>
              ) : (
                <>
                  <div className="product-main-area">
                    <div className="row">
                      <>
                        <div className="col-lg-5 col-md-6 col-12">
                          <div className="flexslider">
                            <img
                              src={
                                boi.files && boi.files.length > 0
                                  ? boi.base_image.path
                                  : "/img/product/6.jpg"
                              }
                              alt=""
                              height="379px"
                              width="264px"
                            />
                          </div>
                        </div>
                        <div className="col-lg-7 col-md-6 col-12">
                          <div className="product-info-main">
                            <div className="page-title">
                              <h1>{boi ? boi.name : ""}</h1>
                            </div>
                            <div className="product-info-stock-sku">
                              <span style={{ fontWeight: "bolder" }}>
                                লেখক:
                              </span>
                              {boi.writers
                                ? boi.writers.map((item) => (
                                    <Link
                                      key={item.id}
                                      to={`/books/authors/${item?.id}`}
                                    >
                                      <span
                                        style={{
                                          textDecoration: "none",
                                          fontFamily: "open Sans",
                                          color: "#000",
                                          paddingRight: "10px",
                                        }}
                                      >
                                        {item.name}
                                      </span>
                                    </Link>
                                  ))
                                : ""}
                              <div className="product-attribute">
                                <span></span>
                                <span className="value"></span>
                              </div>
                              <p>
                                <span style={{ fontWeight: "bolder" }}>
                                  প্রকাশনী :
                                </span>{" "}
                                {boi.publisher && (
                                  <Link
                                  to={`/books/publishers/${boi?.publisher?.id}`}
                                >
                                  <span
                                    style={{
                                      textDecoration: "none",
                                      fontFamily: "open Sans",
                                      color: "#000",
                                      paddingRight: "10px",
                                    }}
                                  >
                                    {boi?.publisher.name}
                                  </span>
                                </Link>
                                )}
                              </p>
                            </div>
                            <div className="product-reviews-summary">
                              <div className="rating-summary">
                                <Rating
                                  initialRating={test}
                                  readonly
                                  emptySymbol="far fa-star fa-1x "
                                  fullSymbol="fas fa-star  fa-1x "
                                  style={{ color: "#259030" }}
                                />
                                {/* <Stars
                                  reviews={ratings}
                                  loading={loading}
                                  avg={avgStar}
                                  test={test}
                                /> */}
                              </div>

                              <div className="reviews-actions ">
                                <Link2
                                  to="reviews"
                                  spy={true}
                                  smooth={true}
                                  duration={1000}
                                >
                                  {" "}
                                  {ratings ? ratings.length : 0} Reviews{" "}
                                </Link2>
                                <Link2
                                  to="reviews"
                                  spy={true}
                                  smooth={true}
                                  duration={1000}
                                  className="view"
                                  style={{ textDecoration: "none" }}
                                >
                                  Add Your Review
                                </Link2>
                              </div>
                            </div>
                            <div className="product-info-price">
                              <div className="price-final">
                                {boi.special_price ? (
                                  <span>
                                    Tk. {boi.special_price.amount * value}{" "}
                                  </span>
                                ) : (
                                  ""
                                )}
                                {boi.selling_price ? (
                                  <span className="old-price">
                                    Tk. {boi.price.amount * value}
                                  </span>
                                ) : (
                                  ""
                                )}

                                <span
                                  style={{ fontSize: "12px" }}
                                  className="text-secondary"
                                >
                                  {" "}
                                  ( You Save per book TK.{" "}
                                  {boi.selling_price && boi.special_price ? (
                                    <span>
                                      {boi.price.amount -
                                        boi.selling_price.amount}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  ){" "}
                                </span>
                              </div>
                              <p className="mt-1">
                                {boi.in_stock ? (
                                  <span>
                                    <i
                                      className="fas fa-check-circle"
                                      style={{ color: "green" }}
                                    ></i>{" "}
                                    In Stock{" "}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </p>
                            </div>
                            <div className="product-add-form">
                              <form action="#">
                                <Link
                                  to="#"
                                  style={{ textDecoration: "none" }}
                                  onClick={() => addToCart(boi)}
                                >
                                  Add to cart
                                </Link>
                                <Link
                                  to="#"
                                  style={{ marginLeft: "0.5rem" }}
                                  onClick={
                                    state.isAuthenticated
                                      ? () => createWishList(boi.id)
                                      : () => redirect()
                                  }
                                >
                                  <i className="fa fa-heart"></i>
                                </Link>
                              </form>
                            </div>
                            <div className="product-social-links">
                              <div className="product-addto-links-text">
                                <p></p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    </div>
                  </div>

                  <ProductInfo datas={boi.attributes} books={boi} />
                </>
              )}
            </div>
            {relatedBooks.length > 0 ? "" : ""}
            <div className="col-lg-3 col-md-12 col-12 order-lg-2 order-2">
              <div className="shop-left related-slider">
                {relatedBooks.length > 0 ? (
                  <div className="left-title" style={{ marginBottom: "20px" }}>
                    <h4>Related Products</h4>
                  </div>
                ) : (
                  ""
                )}

                <MultiThreeRowSlickSlider
                  start="18"
                  end="24"
                  books={relatedBooks}
                />
                <div className="banner-area " style={{ marginBottom: "30px" }}>
                  <div className="banner-img-2">
                    <Link to="#">
                      <img src="/img/Boi-Bazaar/My-wishlist.jpg" alt="banner" />
                    </Link>
                  </div>
                </div>

                <div className="left-title-2">
                  <h2>My Wish List</h2>
                  <p>You have no items in your wish list.</p>
                </div>
              </div>
            </div>
          </div>
          {/* <Test title="জনপ্রিয় বই সমুহ" start="0" end="6" /> */}
        </div>
      </div>
      <ReviewRatings
        rating={avgStar}
        reviews={ratings}
        loading={loading}
        setReviews={setRatings}
      />
    </>
  );
};

export default BookDetails;
