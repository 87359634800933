import axios from "axios";
import React, { useContext, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { CartContext } from '../Context/GlobalContext/GlobalState';
import { url } from "../Services/Url";
const MultirowSlickSlider = ({ start, end }) => {
  const settings = {
    infinite: true,
    slidesToShow: 2,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    rows: 2,
    slidesPerRow: 1,
    slidesToScroll: 2,
    arrows: false,
    dots: false
  };

  const [data, setData] = useState([]);
  const { cart, addToCart } = useContext(CartContext);

  useEffect(() => {
    axios.get(`${url}/api/v1/products`)
      .then((res) => {
        setData(res.data.products)
      })
  }, [])
  const books = data.slice(start, end).map((item) => item)
  return (
    <>


      {data.length > 0 ?
        <Slider {...settings} className="multirow-slider">
          {books.map((item,index) => (
            <div className="single-bestseller" style={{ marginBottom: "25px" }} key={index}>
              <div className="bestseller-img">
                <Link to="#">
                  <img
                    src={item.files.length
                      ? item.base_image.path
                      : "/img/product/1.jpg"}
                    alt="book"
                    style={{ width: "80%" }}
                  />
                </Link>
                <div className="product-flag">
                  <ul>
                    <li>
                      <span className="sale">new</span>
                    </li>
                    <li>
                      <span className="discount-percentage">-5%</span>
                    </li>
                  </ul>
                </div>
                <div className="bestseller-text mt-1">
                  <h3 style={{ fontSize: "12px" }}>
                    {" "}
                    <Link to={{ pathname: `/product-details/${item.slug}` }}
                 
                      ><span style={{fontFamily:"Hind Siliguri"}}>{item.name}</span></Link>
                  </h3>
                  <p>
                    <span style={{ fontWeight: "500" }}>
                    {" "}
                    {item.writers
                      ? item.writers.map((item) => (
                        <span
                          key={item.id}
                          style={{
                            fontFamily: "Hind Siliguri",
                          }}
                        >
                          {item.name}
                        </span>
                      ))
                      : ""}
                    </span>
                  </p>
                  <div className="price">
                    <ul>
                      <li>
                        <span className="new-price">
                          TK. {parseFloat(item.special_price.amount).toFixed(2)}{" "}
                        </span>
                      </li>
                      <li>
                        <span className="old-price">
                          Tk. {parseFloat(item.price.amount).toFixed(2)}{" "}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

            </div>
          ))}

        </Slider> : ""
      }
    </>
  )
}

export default MultirowSlickSlider
