import axios from "axios";
import React from "react";
import { Link } from "react-router-dom";
import { url } from "../Services/Url";
import NavMenuChildren from "./NavMenuChildren";

class NavMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authors: [],
      publications: [],
    };
  }
 

  componentDidMount() {
    axios.get(`${url}/api/v1/authors/navMenu`).then((response) => {
      this.setState({
        authors: response.data,
      });
    });

    axios.get(`${url}/api/v1/publishers/navMenu`).then((response) => {
      this.setState({
        publications: response.data,
      });
    });
  }


  render() {
    const linkStyle = {
      textDecoration: "none",
    };

    return (
      <>
        <div
          className="main-menu-area d-md-none d-none d-lg-block sticky-header-1"
          id="header-sticky"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="menu-area">
                  <nav>
                    <ul style={{ paddingLeft: "0px" }}>
                      {/* {this.state.navigation.map((item, index) => (
                        <li key={index}>
                          <Link
                            to={{
                              pathname: LinkUrl({
                                type: item.type,
                                url: item.url,
                                item,
                              }),
                            }}
                            style={linkStyle}
                          >
                            {item.name}
                            {item.children.length > 0 ? (
                              <i className="fa fa-angle-down"></i>
                            ) : (
                              ""
                            )}
                          </Link>
                          {item.children.length > 0 ? (
                            <NavMenuChildren children={item.children} />
                          ) : (
                            <></>
                          )}
                        </li>
                      ))} */}

                      <li>
                        <Link to="/" style={linkStyle}>
                          হোম
                        </Link>
                      </li>

                      <li>
                        <Link to="/books" style={linkStyle}>
                          সকল বই
                        </Link>
                      </li>

                      <li>
                        <Link to="/authors" style={linkStyle}>
                          লেখক
                          <i className="fa fa-angle-down"></i>
                        </Link>
                        <NavMenuChildren children={this.state.authors} url='/books/authors/'/>
                      </li>

                      <li>
                        <Link to="/publications" style={linkStyle}>
                          প্রকাশনী
                          <i className="fa fa-angle-down"></i>
                        </Link>
                        <NavMenuChildren children={this.state.publications} url='/books/publishers/' />
                      </li>

                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default NavMenu;
