import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ScaleLoader } from "react-spinners";
import { url } from "../Services/Url";
import SingleGridBook from "./SingleGridBook";

const TapPublisherBook = () => {
  let { slug } = useParams();
  const [name, setname] = useState("");
  const [books, setBooks] = useState([]);
  const [loading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setlastPage] = useState(1);
  const [total, setTotal] = useState(0);

  const fetchBook = async () => {
    setLoading(true);
    await axios
      .get(`${url}/api/v1/publishers/getbooks/${slug}`, {
        params: { page: currentPage },
      })
      .then((res) => {
        setBooks(res.data.products);
        setname(res.data.name);
        setlastPage(res?.data?.last_page);
        setTotal(res?.data?.total);
      });
    setLoading(false);
  };
  useEffect(() => {
    fetchBook();
  }, [slug, currentPage]);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const nextPage = () => setCurrentPage(currentPage + 1);
  const prevPage = () => setCurrentPage(currentPage - 1);

  return (
    <div className="col-lg-9 col-md-12 col-12 order-lg-2 order-1">
      <div className="section-title-5" style={{ marginBottom: "30px" }}>
        <h2>প্রকাশনী: {name}</h2>
      </div>
      {/* <Breadcumbs /> */}
      {loading ? (
        <div style={{ textAlign: "center" }}>
          <ScaleLoader color="#24902f" />
        </div>
      ) : (
        <div className="row">
          {books.length ? (
            <>
              {books?.map((item, index) => (
                <Fragment key={index}>
                  <SingleGridBook key={item.id} book={item} loading={loading} />
                </Fragment>
              ))}
              {/* {total > 12 && (
                <Pagination2
                  firstPage={1}
                  lastPage={lastPage}
                  currentPage={currentPage}
                  paginate={paginate}
                  nextPage={nextPage}
                  prevPage={prevPage}
                />
              )} */}
            </>
          ) : (
            <div>
              <p style={{ textAlign: "center" }}>Not Found</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TapPublisherBook;
