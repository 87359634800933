import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { url } from "../Services/Url";
import Pagination2 from "./Pagination2";
const AuthorsList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setlastPage] = useState(1);
  const [publishers, setPublishers] = useState([]);

  const fetchData = async () => {
    await axios
      .get(`${url}/api/v1/authors-list`, { params: { page: currentPage } })
      .then((res) => {
        setPublishers(res?.data?.products);
        setlastPage(res?.data?.last_page);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const nextPage = () => setCurrentPage(currentPage + 1);
  const prevPage = () => setCurrentPage(currentPage - 1);

  return (
    <>
      <div className="container mt-5 mb-5">
        <div className="container">
          <hr />
          <div className="row" style={{ justifyContent: "center" }}>
            {publishers?.map((data, index) => (
              <div className="col-sm-2" key={index} style={{ marginLeft: 0 }}>
                <div>
                  <Link
                    to={`/books/authors/${data?.id}`}
                    style={{ textDecoration: "none", color: "#c7c7c7" }}
                    className="circle-link"
                  >
                    <img
                      className="card-img-top ml-2"
                      id="circle-img"
                      src={
                        data.image ??
                        "/img/author.png"
                      }
                      alt=""
                      style={{
                        borderRadius: "50%",
                        border: "1px solid #c7c7c7",
                        width: "140px",
                        height: "140px",
                      }}
                    />
                  </Link>
                  <div className="card-body text-center">
                    <Link
                      to={`/books/authors/${data?.id}`}
                      className="authors-title card-title"
                      style={{ textDecoration: "none", color: "#333" }}
                    >
                      {data.name}
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <Pagination2
              firstPage={1}
              lastPage={lastPage}
              currentPage={currentPage}
              paginate={paginate}
              nextPage={nextPage}
              prevPage={prevPage}
            />

        </div>
      </div>
    </>
  );
};

export default AuthorsList;
