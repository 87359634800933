import React, { useContext } from "react";
import Breadcumbs from "../Components/Breadcumbs";
import PublicationBanner from "../Components/PublicationBanner";
import PublicationsList from "../Components/PublicationsList";
import StickyButton from "../Components/StickyButton";
import StickyWishlist from "../Components/StickyWishlist";
import { AuthContext } from "../Context/AuthState";
const Publications = () => {
  const {state} = useContext(AuthContext)
  
  return (
    <>
     {state.isAuthenticated ?   <StickyWishlist/> : ""}
      <StickyButton />

      <Breadcumbs />
      <PublicationBanner />
      {/* <WeeklyTopPublications /> */}
      <PublicationsList />
    </>
  );
};

export default Publications;
