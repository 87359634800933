import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { ScaleLoader } from "react-spinners";
import Breadcumbs from "./Breadcumbs";
import Pagination2 from "./Pagination2";
import SingleGridBook from "./SingleGridBook";
const TabBook2 = ({Allbooks, loading, currentPage, paginate, nextPage, prevPage}) => {
  return (
    <>
      <div className="col-lg-9 col-md-12 col-12 order-lg-2 order-1">
        <div className="category-image" style={{ marginBottom: "30px" }}>
          <Link to="#">
            <img
              src="/img/Boi-Bazaar/Header-for-book-list-page.jpg"
              alt="banner"
              style={{ width: "100%" }}
            />
          </Link>
        </div>
        <div className="section-title-5" style={{ marginBottom: "30px" }}>
          <h2>Book</h2>
        </div>
        <Breadcumbs />
        {loading ? (
          <div style={{textAlign: "center"}}>
            <ScaleLoader color="#24902f" />
          </div>
        ) : (
          <>
            <div className="row">
              {Allbooks?.products?.map((item, index) => (    
                <Fragment key={index}>
                  <SingleGridBook key={index} book={item} loading={loading} />
                </Fragment>                
              ))}
            </div>
            <Pagination2
              firstPage={Allbooks.first_page}
              lastPage={Allbooks.last_page}
              loading={loading}
              currentPage={currentPage}
              paginate={paginate}
              nextPage={nextPage}
              prevPage={prevPage}
            />
          </>
        )}
      </div>
    </>
  );
};

export default TabBook2;
