import React from "react";
import { useLocation, useParams } from "react-router-dom";
import TapSearch from "../Pages/Search/TapSearch";
import TabBook2 from "./TabBook2";
import TapAuthorBook from "./TapAuthorBook";
import TapCategoriesBook from "./TapCategoriesBook";
import TapPublisherBook from "./TapPublisherBook";

const BookLists = ({data, loading, currentPage, paginate, nextPage, prevPage} )=> {
  let { id, name, slug } = useParams();
  const location = useLocation();

  return (
    <>
      {location.pathname === `/books/authors/${id}` ? (
        <TapAuthorBook data={data} />
      ) : location.pathname === `/books/publishers/${slug}` ? (
        <TapPublisherBook data={data} />
      ) : location.pathname === `/books/categories/${slug}` ? (
        <TapCategoriesBook data={data} />
      ) : location.pathname === `/books/search/${name}` ? (
        <TapSearch />
      ) : (
        <TabBook2 
        Allbooks={data}
            loading={loading}
            currentPage={currentPage}
            paginate={paginate}
            nextPage={nextPage}
            prevPage={prevPage}
            />
      )}
    </>
  );
};

export default BookLists;
